@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

body {
  margin: 0;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  background: none transparent !important;
}

.embed-root {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.embed-root.dark {
  background: #191919;
}

.embed-root.font-default * {
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
}

.embed-root.font-serif * {
  font-family: "Lyon-Text", Georgia, ui-serif, serif;
}

.embed-root.font-mono * {
  font-family: 'Roboto Mono', iawriter-mono, Nitti, Menlo, Courier, monospace;
}

button {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}